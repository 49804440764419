import {Link} from "react-router-dom";

export default function AgreementPrompt({checkboxRef}) {
    return (
        <div className="p-3 my-2 bg-body-tertiary" style={{borderRadius: '10px'}}>
            <input
                type="checkbox"
                id="checkbox"
                defaultChecked={false}
                ref={checkboxRef}
            />
            <label htmlFor="checkbox">
                &nbsp;- I agree to Genotyper.org's&nbsp;
                <Link to="/terms" target='_blank'>Terms of Service</Link> and&nbsp;
                <Link to="/privacy" target='_blank'>Privacy Policy</Link>.
            </label>
        </div>
    );
}