import {useState} from "react";
import {Link} from "react-router-dom";
import {Button, Checkbox, Divider, FormControlLabel, Paper, Stack, TextField} from "@mui/material";
import {makeId, sxCenter} from "../../utils";
import Container from "react-bootstrap/Container";
import {submitNewPollApi, uploadCSV, uploadImageFile} from "../../api/apiAdmin";
import {getToken, handleAuthenticationError} from "../../userAuth";
import MCQuestion from "./MCQuestion";
import ShortAnswerQuestion from "./ShortAnswerQuestion";
import LongAnswerQuestion from "./LongAnswerQuestion";
import Form from 'react-bootstrap/Form';

export default function CreatePoll() {

    const [poll, setPoll] = useState({
        author: "",
        title: "",
        details: "",
        date: "",
        cat_mode: false,
        dimensions: '',
    });

    const [questions, setQuestions] = useState([]);

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileSelect = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = async () => {
        if (!selectedFile) {
            alert('Please select a file first!');
            return;
        }

        const formData = new FormData();
        formData.append("token", getToken());
        formData.append("file", selectedFile);
        formData.append("poll_data", JSON.stringify({
            title: poll.title,
            details: poll.details,
            cat_mode: poll.cat_mode,
            dimensions: poll.dimensions,
        }));

        try {
            const response = await uploadCSV(formData);
            console.log('File uploaded successfully', response.data);
        } catch (error) {
            console.error('Error uploading file', error);
        }
    };


    // Thumbnail image selection.
    const changeFile = event => {
        let selectedFile = event.target.files[0];
        if (!selectedFile) return;

        async function uploadImage() {
            try {
                const formData = new FormData();
                formData.append("token", getToken());
                formData.append("image", selectedFile, selectedFile.name);
                const response = await uploadImageFile(formData);
                console.log(response);
            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error uploading image file:', error);
            }
        }

        void uploadImage();
        let newPoll = {...poll};
        newPoll.thumbnail = selectedFile.name;
        setPoll(newPoll);
    };


    // Text Field Changes

    const changeTitle = (event) => {
        let newPoll = {...poll};
        newPoll.title = event.target.value;
        setPoll(newPoll);
    }

    const changeDetails = (event) => {
        let newPoll = {...poll};
        newPoll.details = event.target.value;
        setPoll(newPoll);
    }

    const changeDimensions = (event) => {
        let newPoll = {...poll};
        newPoll.dimensions = event.target.value;
        setPoll(newPoll);
    }

    const changeMeanSD = (event) => {
        let newPoll = {...poll};
        newPoll.meanSD = event.target.value;
        setPoll(newPoll);
    }

    const toggleCat = (event) => {
        let newPoll = {...poll};
        newPoll.cat_mode = !poll.cat_mode;
        setPoll(newPoll);
    };

    // Button Clicks

    const choiceClick = () => {
        setQuestions(questions.concat({
            local_id: makeId(10),
            type: "Multiple Choice",
            prompt: "",
            multi: false,
            choices: []
        }));
    }

    const longClick = () => {
        setQuestions(questions.concat({local_id: makeId(10), type: "Long Answer", prompt: ""}));
    }

    const shortClick = () => {
        setQuestions(questions.concat({local_id: makeId(10), type: "Short Answer", prompt: ""}));
    }

    const submitClick = async () => {
        // Create a new array of questions without the 'local_id' property
        let questionsWithoutLocalId = questions.map(({local_id, ...rest}) => rest);
        if (!poll.cat_mode) {
            questionsWithoutLocalId = questionsWithoutLocalId.map(({difficulty, discrimination, ...rest}) => rest);
        }
        const toSend = {
            title: poll.title,
            details: poll.details,
            thumbnail: poll.thumbnail,
            questions: questionsWithoutLocalId,
            cat_mode: poll.cat_mode,
            dimensions: poll.dimensions,
            token: getToken()
        };
        try {
            await submitNewPollApi(toSend);
            alert('Submission Successful!');
            window.location.reload();
        } catch (error) {
            handleAuthenticationError(error);
            console.log(error);
            alert('Submission Failed! Check account privileges.');
            window.location.reload();
        }

    }

    const deleteQuestion = (oldQuestion) => {
        let newQuestions = [...questions];
        let index = newQuestions.findIndex(question => question.local_id === oldQuestion.local_id);
        newQuestions.splice(index, 1);
        setQuestions(newQuestions);
    }

    const updateQuestion = (updatedQuestion) => {
        // gets a question with a local_id and anything else. Replaces the one with that local_id with this
        let newQuestions = [...questions];
        let index = newQuestions.findIndex(q => q.local_id === updatedQuestion.local_id);
        newQuestions[index] = updatedQuestion;
        setQuestions(newQuestions);
    }

    return (
        <Container>
            <Stack sx={sxCenter} direction="column" spacing={1}>
                <h1 className="mt-4">Create New Poll</h1>
                <Paper sx={{...sxCenter, width: 3.5 / 4}}>
                    <Stack spacing={2} direction="column" divider={<Divider/>}
                           sx={{display: "flex", justifyContent: "center", width: 3 / 3, margin: 2}}>
                        <TextField variant="outlined" label="Title" value={poll.title} onChange={changeTitle}/>
                        <TextField variant="outlined" label="Details" value={poll.details} onChange={changeDetails}/>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Choose poll thumbnail:</Form.Label>
                            <Form.Control type="file" accept=".bmp,.gif,.jpeg,.jpg,.png,.svg" onChange={changeFile}/>
                        </Form.Group>
                        <TextField variant="outlined" label="Dimensions" value={poll.dimensions}
                                   onChange={changeDimensions}/>
                        <TextField variant="outlined" label="Mean/SD" value={poll.meanSD}
                                   onChange={changeMeanSD}/>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={poll.cat_mode}
                                    onChange={toggleCat}
                                />
                            }
                            label="Cognitive Adaptive Testing Mode"
                        />
                        {
                            questions.map((question) => {
                                if (question.type === 'Multiple Choice') {
                                    return (
                                        <Paper elevation={5}>
                                            <Stack direction="column" spacing={1} sx={{padding: 2}}>
                                                <MCQuestion updateFunc={updateQuestion} deleteFunc={deleteQuestion}
                                                            question={question} cat={poll.cat_mode}/>
                                            </Stack>
                                        </Paper>
                                    )
                                }
                                if (question.type === 'Short Answer') {
                                    return (
                                        <Paper elevation={5}>
                                            <Stack direction="column" spacing={1} sx={{padding: 2}}>
                                                <ShortAnswerQuestion updateFunc={updateQuestion}
                                                                     deleteFunc={deleteQuestion} question={question}/>
                                            </Stack>
                                        </Paper>
                                    )
                                }
                                if (question.type === 'Long Answer') {
                                    return (
                                        <Paper elevation={5}>
                                            <Stack direction="column" spacing={1} sx={{padding: 2}}>
                                                <LongAnswerQuestion updateFunc={updateQuestion}
                                                                    deleteFunc={deleteQuestion} question={question}/>
                                            </Stack>
                                        </Paper>
                                    )
                                }
                            })
                        }
                        <Stack direction="row" spacing={2} sx={sxCenter}>
                            <h6>Add Question:</h6>
                            <Button variant="contained" onClick={choiceClick}>Multiple Choice</Button>
                            <Button variant="contained" onClick={shortClick}>Short Answer</Button>
                            <Button variant="contained" onClick={longClick}>Long Answer</Button>
                        </Stack>
                        <Button variant="contained" sx={{width: "fit-content"}} onClick={submitClick}>Submit
                            Poll</Button>
                        <Form.Group controlId="formFileCSV" className="mb-3">
                            <Form.Label>Upload Questions CSV:</Form.Label>
                            <Form.Control type="file" accept=".csv" onChange={handleFileSelect}/>
                        </Form.Group>
                        <button onClick={handleSubmit}>Upload</button>
                    </Stack>
                </Paper>
                <br/>
                <Link to="/">Back to Home</Link>
            </Stack>
        </Container>
    )
}