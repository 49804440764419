import {Button} from "@mui/material";

export default function ControlButton({handleClick, isDisabled, buttonText}) {
    return (
        <Button
            variant="contained"
            onClick={handleClick}
            disabled={isDisabled}
            sx={{width: "fit-content"}}
        >{buttonText}</Button>
    );
}
