import {FormControl, FormGroup, Paper, RadioGroup, TextField} from "@mui/material";
import ChoiceCheckbox from "./ChoiceCheckbox";
import ChoiceRadio from "./ChoiceRadio";
import DOMPurify from "dompurify";


export default function CurrentQuestion({question, answer, setAnswer, selections, setSelections}) {
    const safeHTML = DOMPurify.sanitize(question?.prompt);
    return (
        <Paper elevation={5} sx={{margin: 2, padding: 3}}>
            <h6>Question: {`${question?.instructions ?? ''}`}</h6>
            <div 
                className="image-container" 
                style={{ textAlign: 'center' }}
                dangerouslySetInnerHTML={{ __html: safeHTML }} 
            />
            <style jsx>{`
                .image-container img {
                    width: 100%;
                    height: auto;
                    max-width: 200px; /* Adjust as necessary */
                }

                @media (min-width: 600px) {
                    .image-container img {
                        max-width: 500px;
                    }
                }

                @media (min-width: 900px) {
                    .image-container img {
                        max-width: 500px;
                    }
                }
            `}</style>
            {
                question.type === "Long Answer" &&
                <TextField variant="outlined" fullWidth multiline maxrows={30}
                           label="Reply (Long Answer)" value={answer.reply}
                           onChange={(event) => {
                               setAnswer({
                                   reply: event.target.value,
                                   choice_id: null,
                                   q_id: question.id,
                               });
                           }}/>
            }
            {
                question.type === "Short Answer" &&
                <TextField variant="outlined" fullWidth label="Reply (Short Answer)"
                           value={answer.reply}
                           onChange={(event) => {
                               setAnswer({
                                   reply: event.target.value,
                                   choice_id: null,
                                   q_id: question.id,
                               });
                           }}/>
            }
            {
                question.type === "Multiple Choice" && <>
                    {
                        question.multi && <FormGroup>
                            {
                                question.choices.map((choice) => {
                                    return (
                                        <ChoiceCheckbox
                                            choice={choice}
                                            selections={selections}
                                            setSelections={setSelections}
                                        />
                                    );
                                })
                            }
                        </FormGroup>
                    }
                    {
                        !question.multi && <FormControl>
                            <RadioGroup>
                                {
                                    question.choices.map((choice) => {
                                        return <ChoiceRadio
                                            choice={choice}
                                            answer={answer}
                                            setAnswer={setAnswer}
                                        />;
                                    })
                                }
                            </RadioGroup>
                        </FormControl>
                    }
                </>
            }
        </Paper>
    )
}