import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import bellCurve from "highcharts/modules/histogram-bellcurve";

bellCurve(Highcharts);

function meanSD(data)
{
    let mean = 0;
    for (let i = 0; i < data.length; ++i)
    {
        mean += data[i] / data.length;
    }
    let sd = 0
    for (let i = 0; i < data.length; ++i)
    {
        sd += (data[i] - mean)*(data[i] - mean) / data.length;
    }
    return [mean, sd];
}

export function Resultsgram(props) {
    const data = props.data;
    const score = props.scores.score;
    const se = props.se;
    const config = {
        chart: {
            height: props.height || null,
        },

        title: {
            text: props.title || null
        },

        legend: {
            enabled: false
        },

        tooltip: {
            enabled: false
        },

        xAxis: [
            {
                visible: true,
                plotLines: [{
                    color: '#DD0000',
                    width: 2,
                    value: score
                }],
            }
        ],

        yAxis: [
            {
                title: {
                    text: 'Frequency'
                }
            }
        ],

        series: [
            {
                name: 'Histogram',
                type: 'histogram',
                baseSeries: 's1',
                zIndex: -1,
                color: '#AAAAAA',
            },
            {
                name: 'Data',
                type: 'scatter',
                data: data,
                id: 's1',
                marker: {
                    radius: 1.5
                },
                visible: false
            }
        ],

        credits: {
            enabled: false
        },
    };

    return <HighchartsReact highcharts={Highcharts} options={config} />;
}