import Container from "react-bootstrap/Container";
import {changePasswordApi} from "../../api/apiAuth";
import {getToken} from "../../userAuth";
import {Card} from "react-bootstrap";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

export default function ChangePassword() {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const executeChange = async () => {
        if (password !== confirmPassword) {
            alert("Passwords don't match");
            return;
        }
        try {
            void changePasswordApi({token: getToken(), new_password: password});
            alert("Password changed successfully!");
            navigate("/account");
        } catch (e) {
            alert("Failed to change password!");
            console.log(e);
        }
    }

    return (
        <Container className='mt-5'>
            <Card className="m-auto align-self-center" style={{width: '36rem'}}>
                <Card.Body>
                    <Card.Title>Change Password</Card.Title>
                    <div className="Auth-form-container">
                        <form className="Auth-form">
                            <div className="form-group mt-3">
                                <label>Password</label>
                                <input
                                    type="password"
                                    className="form-control mt-1"
                                    placeholder="Password"
                                    id="password"
                                    name="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label htmlFor="confirm-password" className="form-label">Confirm Password</label>
                                <input type="password"
                                       className="form-control confirm-password"
                                       placeholder="Confirm Password"
                                       id="confirm-password"
                                       name="confirm-password"
                                       value={confirmPassword}
                                       onChange={(e) => setConfirmPassword(e.target.value)}
                                       required
                                />
                            </div>
                            <div className="d-grid gap-2 mt-3">
                                <button type="submit" className="btn btn-primary" onClick={(e) => {
                                    e.preventDefault();
                                    void executeChange();
                                }}>Change Password
                                </button>
                            </div>
                        </form>
                    </div>
                </Card.Body>
            </Card>
        </Container>
    );
}