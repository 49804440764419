import {useEffect, useState} from "react";
import {getPollNormByShortTitle} from "../../api/apiAnon";
import ErrorBarChart from "./ErrorBarChart";
import {BellCurve} from "./BellCurve";

const CurrentScoreChart = ({short_title, theta, se}) => {

    const [pollNorm, setPollNorm] = useState(null);

    const [minTick, setMinTick] = useState(0.0)
    const [maxTick, setMaxTick] = useState(0.0);

    function setTicks(ticks) {
        setMinTick(ticks.min());
        setMaxTick(ticks.max());
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await getPollNormByShortTitle(short_title);
            setPollNorm(response.data);
        };
        fetchData().catch(console.error);
    });

    return <div className="my-3 mx-5">
        {
            <ErrorBarChart theta={theta} se={se} minTick={minTick} maxTick={maxTick}/>
        }
        {
            pollNorm && <BellCurve scores={{mean: pollNorm.mean, std: pollNorm.std, score: theta}}
                                   se={se}
                                   setTicks={setTicks}/>
        }
    </div>;

}
export default CurrentScoreChart;