import {Card} from "react-bootstrap";
import {getToken, handleAuthenticationError, isAdmin} from "../../userAuth";
import {removePollByShortTitleApi} from "../../api/apiAdmin";
import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {backendUri} from "../../api/config";
import {getPollResponseByUserApi, getAllUserScoresApi} from "../../api/apiUser";
import { makeMatrix } from "../../utils";

export default function PollCard({poll}) {
    const navigate = useNavigate();
    const [hover, setHover] = useState(false);
    const [scoreMatrix, setScoreMatrix] = useState([[]]);

    useEffect(() => {
        const fetchPolls = async () => {
            try {
                const response2 = await getAllUserScoresApi({token: getToken()});
                console.log(makeMatrix(response2.data.scores[poll.short_title]))
                setScoreMatrix(makeMatrix(response2.data.scores[poll.short_title]));

            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error fetching polls', error);
            }

        };

        void fetchPolls();
    }, []);

    function formatScore(scoreVector) {
        if (scoreVector.length === 1) return scoreVector[0].toString();
        return scoreVector.join(", ");
    }
    async function navigateToPoll() {
        let targetRoute = `/poll/${poll.short_title}`;
        try {
            const data = {short_title: poll.short_title, token: getToken()};
            const res1 = await getPollResponseByUserApi(data);
            console.log('SCORE', res1.data.res);

            if (res1.data.res[res1.data.res.length - 1].scores.score !== 'In Progress') {
                targetRoute = `/view/${poll.short_title}`;
            }
        } catch (error) {
            handleAuthenticationError(error);
        } finally {
            navigate(targetRoute);
        }
    }

    function stopPropagation(e) {
        e.stopPropagation();
    }

    async function deletePoll(e) {
        e.preventDefault();
        if (window.confirm('Are you sure you want to delete this poll?')) {
            removePollByShortTitleApi(poll.short_title)
                .then(() => {
                    window.location.reload();
                })
                .catch(error => {
                    console.error('Deletion failed: ', error);
                });
        }
    }

    const cardStyle = {
        maxWidth: '18rem',
        opacity: hover ? 0.5 : 1.0,
        cursor: hover ? 'pointer' : 'auto',
        minHeight: isAdmin() ? 540 : 460,
    }

    // ensure there's a thumbnail.
    if (!poll.thumbnail) poll.thumbnail = 'logo.png';

    return <Card onClick={navigateToPoll} bg="light" border="secondary" className="m-2 text-center p-0"
                 style={cardStyle} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        <Card.Img variant="top" src={`${backendUri}/images/${poll.thumbnail}`}/>
        <Card.Body className="d-flex flex-column">
            <Card.Title className="m-2 ellipsis-2-lines">{poll.title}</Card.Title>
            <Card.Text className="ellipsis-3-lines">{poll.details}</Card.Text>
            {
                scoreMatrix[0].length > 0 &&
                <>
                    <Card.Text className="ellipsis-3-lines">1st score: {formatScore(scoreMatrix[0])}</Card.Text>
                    <Card.Text className="ellipsis-3-lines">Most recent score: {formatScore(scoreMatrix[scoreMatrix.length-1])}</Card.Text>
                </>
            }
            {
                isAdmin() &&
                <Card.Footer onClick={stopPropagation} className="mt-auto"
                             onMouseEnter={() => setHover(false)} onMouseLeave={() => setHover(true)}>
                    <Card.Link href={`/viewAll/${poll.short_title}`}>View Results</Card.Link><br/>
                    <Card.Link href={`/update/${poll.short_title}`}>Update</Card.Link>
                    <Card.Link href={`/norms/${poll.short_title}`}>Norms</Card.Link>
                    <Card.Link href={'./'} onClick={deletePoll}>Delete</Card.Link>
                </Card.Footer>
            }
        </Card.Body>
    </Card>;
}