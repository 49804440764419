import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {Link} from "react-router-dom";
import {getAllPollsApi} from "../../api/apiAnon";
import {handleAuthenticationError, isAdmin} from "../../userAuth";

function TopBar() {

    const [polls, setPolls] = useState(null);

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getAllPollsApi('false');
                setPolls(response.data);
            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error fetching polls:', error);
            }
        }

        void fetchData();
    }, []);

    const getUser = () => {
        const user = localStorage.getItem('email');
        if (user) {
            return <Link to="/account">{user}</Link>;
        }
    };

    return (
        <Navbar expand="lg" className="bg-body-tertiary mb-4">
            <Container>
                <Navbar.Brand href="/"><i>🧬 | <tt><b>Genotyper</b>.org</tt></i></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/genomics">DNA</Nav.Link>
                        <NavDropdown title="Tests" id="basic-nav-dropdown">
                            {
                                polls && polls.map((poll) => {
                                    return <NavDropdown.Item key={poll.title}
                                        href={`/poll/${poll.short_title}`}>{poll.title}</NavDropdown.Item>;
                                })
                            }
                            {
                                isAdmin() && <>
                                    <NavDropdown.Divider/>
                                    <NavDropdown.Item href="/create">
                                        Create a Poll
                                    </NavDropdown.Item>
                                </>
                            }
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text className="border p-2 px-3 bg-body-secondary" style={{borderRadius: '25px'}}>
                        {getUser() || <><Link to="/signin">Log In</Link> | <Link to="/signup">Sign Up</Link></>}
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default TopBar;