import {Stack} from "@mui/material";
import {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {getResultsApi} from "../../api/apiAdmin";
import {Card} from "react-bootstrap";
import ExportCSVButton from '../exportCSV/ExportCSVButton';
import {getPollByShortTitleApi} from "../../api/apiAnon";
import {handleAuthenticationError} from "../../userAuth";
import ScoreBarChart from "./ScoreBarChart";
import DOMPurify from "dompurify";

export default function ViewResults() {
    const {short_title} = useParams();
    const [poll, setPoll] = useState(null);
    const [pollResults, setPollResults] = useState(null);
    const [pollScores, setPollScores] = useState(null);

    useEffect(() => {
        async function fetchData() {
            const data = {
                short_title: short_title,
                token: localStorage.getItem("authToken"),
            };
            try {
                const response1 = await getPollByShortTitleApi(short_title);
                setPoll(response1.data);
                const response2 = await getResultsApi(data);
                console.log(response1.data);
                setPollResults(response2.data.results);
                console.log(response2.data);
                setPollScores({
                    data: response2.data.results.map(pr => pr.total_score),
                    score: 0, // change this later!
                });
            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error fetching results:', error);
            }
        }

        void fetchData();
    }, []);

    return (
        poll && pollResults && (
            <Stack sx={{display: "flex", alignItems: "center"}}>
                <h2>All User Results for {poll.title}</h2>
                <Stack direction="row" spacing={2}
                       sx={{width: 3 / 4, justifyContent: 'space-between', alignItems: 'center'}}>
                    <h1>Answers</h1>
                    <ExportCSVButton id={poll.short_title}/>
                </Stack>
                <Stack spacing={2} sx={{width: 3 / 4}}>
                    {poll.questions.map((question, index) => {
                        let answers = pollResults.map(pr =>
                            [pr.email, pr[question.prompt].map(ans => [ans.answer, ans.score])]
                        );
                        const safeHTML = DOMPurify.sanitize(question.prompt);
                        return (
                            <Card key={index}>
                                <Card.Body>
                                    <Card.Title>
                                        <div dangerouslySetInnerHTML={{__html: safeHTML}}/>
                                    </Card.Title>
                                    {
                                        answers.map(
                                            (answer, answerIndex) => (
                                                <div key={answerIndex}
                                                     style={{
                                                         display: 'flex',
                                                         alignItems: 'center',
                                                         width: '100%',
                                                         marginTop: 32
                                                     }}>
                                                    <div
                                                        style={{
                                                            width: '20%',
                                                            textAlign: 'left',
                                                            whiteSpace: 'nowrap',
                                                            marginRight: 32
                                                        }}>
                                                        {answer[0]}
                                                    </div>
                                                    {
                                                        answer[1].map((subAnswer, subAnswerIndex) => {
                                                                const safeHTML = DOMPurify.sanitize(subAnswer[0]);
                                                                return <div key={subAnswerIndex} style={{marginLeft: 64}}>
                                                                    <div style={{flex: 1, fontWeight: "bold"}}>
                                                                    <span className="choice-content"
                                                                          dangerouslySetInnerHTML={{__html: safeHTML}}/>
                                                                    </div>
                                                                    <div style={{flex: 0, whiteSpace: 'nowrap'}}>
                                                                        Score: {subAnswer[1]}
                                                                    </div>
                                                                </div>
                                                            }
                                                        )
                                                    }
                                                </div>
                                            )
                                        )
                                    }
                                </Card.Body>
                            </Card>
                        );
                    })}
                </Stack>
                <Stack sx={{width: '75%', maxWidth: '600px', margin: 'auto'}}>
                    <ScoreBarChart scores={pollScores.data}/>
                </Stack>
                <Link to="/">Back to Home</Link>
            </Stack>
        )
    )
}