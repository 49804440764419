import {axios, backendUri} from "./config";

export const uploadDNAFile = (formData) => axios.post(`${backendUri}/uploadDNA`, formData, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

export const hasUploadedDNAFile = (props) => axios.post(`${backendUri}/hasUploadedDNA`, props);

export const cancelDNAFile = (props) => axios.post(`${backendUri}/DNAcancel`, props);

export const getGenomicResultsApi = (props) => axios.post(`${backendUri}/getGenomicResults`, props);
