import Container from "react-bootstrap/Container";


export function Stats() {
    return (
        <Container className="mt-5">
            <h1>Statistics Info</h1>
            <p>
                Genotyper uses a few statistical concepts that are helpful to understand if you want to interpret your scores. 
            </p>
            <h2>
                Normal distributions
            </h2>
            <p>
                A normal distribution, often referred to as a bell curve, is a way to represent data that clusters around a mean (average) value. In this distribution, most of the observations are concentrated around the central peak, and the probabilities for values further away from the mean taper off equally in both directions. This concept is crucial in statistics because many biological characteristics are normally distributed, making it a fundamental basis for many statistical tests used in Genotyper to compare and interpret genetic variations.
            </p>

            <h2>
                Z-scores
            </h2>

            <p>
                A Z-score, or standard score, is a numerical measurement that describes a value's relationship to the mean of a group of values, measured in terms of standard deviations (spread) from the mean. If you have a set of data, you get the Z score of each data point by substracting each data point from the overall mean, and then by dividing by the standard deviation. If a Z-score is 0, it indicates that the data point's score is identical to the mean score. A Z-score can be positive or negative, indicating whether the data point is above or below the mean, respectively. Genotyper uses Z-scores to help determine how unusual or typical a particular genetic variation is compared to a reference group. Z-scores are usually between -3 and 3.
            </p>

            <h2>
                Percentiles
            </h2>

            <p>
                Percentiles are measures that indicate the value below which a given percentage of observations in a group of observations falls. For example, the 50th percentile is the median; if you score in the 90th percentile, it means you scored better than 90% of the people in the reference group. In Genotyper, percentiles can provide insight into where your genetic markers stand in relation to a broader population, offering a clear and intuitive way to understand genetic scoring. In a normal distribution, Z scores can be related to percentiles in the following way: -3 is about .3rd percentile, -2 is about 2nd percentile, -1 is about 15th percentile, 0 is 50th percentile, 1 is about 85th percentile, 2 is about 98th percentile, and 3 is about 99.7th percentile. 
            </p>
            <p>
                Below is a image showing a normal distribution where the data has been standardized into Z-scores. Any time a normal distribution is standardized, the exact probabilities of being between the Z score values are as shown on the chart. For example, there is a 68.2% probability of being between -1 and 1.
            </p>
            <div className="text-center">
        <img src="https://www.w3schools.com/statistics/img_standard_normal.svg" alt="Standard Normal Distribution" style={{ maxWidth: '100%', height: 'auto' }} />
    </div>

        </Container>
        
    );
}
