import {axios, backendUri} from "./config";
import {getToken} from "../userAuth";

export const submitNewPollApi = (props) => axios.post(`${backendUri}/submitNewPoll`, props);

export const updatePollApi = (props) => axios.post(`${backendUri}/updatePoll`, props);

export const removePollByShortTitleApi = (short_title) => axios.post(`${backendUri}/removePoll/${short_title}`, {token: getToken()});

export const getResultsApi = (props) => axios.post(`${backendUri}/pollResults`, props);

export const getCSV = (props) => axios.post(`${backendUri}/export_csv`, props, {responseType: 'blob'});

export const uploadImageFile = (formData) => axios.post(`${backendUri}/uploadImage`, formData, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

export const uploadCSV = (formData) => axios.post(`${backendUri}/uploadCSV`, formData, {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});

export const sendNorms = (props) => axios.post(`${backendUri}/sendNorms`, props);

export const getNormsByShortTitle = (props) => axios.post(`${backendUri}/getNormsByShortTitle`, props);