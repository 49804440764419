import {Dashboard} from "@uppy/react";
import Uppy from "@uppy/core";
import Webcam from "@uppy/webcam";

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/file-input/dist/style.css'
import '@uppy/progress-bar/dist/style.css'
import '@uppy/webcam/dist/style.css'
import {getToken, handleAuthenticationError} from "../../userAuth";
import {getProfileImageApi, removeProfileImageApi, uploadProfileImageApi} from "../../api/apiUser";
import {useEffect, useState} from "react";
import Container from "react-bootstrap/Container";
import {Button, Card} from "react-bootstrap";


export default function Profile() {
    const [image, setImage] = useState(null)

    const uppy = new Uppy({restrictions: {maxNumberOfFiles: 1, allowedFileTypes: ['image/*']}})
        .use(Webcam, {modes: ["picture"]})
        .on('complete', (result) => {
            console.log('Upload result:', result);
            const imageResult = result['successful'][0];
            const formData = new FormData();
            formData.append("token", getToken());
            formData.append("image", imageResult['data'], imageResult['name']);
            void uploadProfileImage(formData);
        })

    async function uploadProfileImage(formData) {
        try {
            await uploadProfileImageApi(formData);
            uppy.close()
            void fetchProfileImage();
        } catch (error) {
            console.log(error)
            handleAuthenticationError(error);
            if (error.response.status === 400) {
                alert(error.response.data.message);
                uppy.cancelAll()
            }
        }
    }

    async function fetchProfileImage() {
        try {
            const response = await getProfileImageApi({token: getToken()});
            setImage(URL.createObjectURL(response.data))
        } catch (error) {
            handleAuthenticationError(error);
        }
    }

    async function removeProfileImage() {
        if (!window.confirm("Are you sure you want to delete your profile picture?")) return;
        try {
            await removeProfileImageApi({token: getToken()});
            setImage(null);
        } catch (error) {
            handleAuthenticationError(error);
        }
    }

    useEffect(() => {
        void fetchProfileImage();
    }, [setImage])


    return (
        <Container className="p-4 pb-3 bg-body-tertiary" style={{borderRadius: '10px', maxWidth: '36rem'}}>
            {
                image ? (
                    <Card>
                        <Card.Img src={image} alt="Profile Image"/>
                        <Card.Body>
                            <Card.Text>
                                Your profile picture was successfully processed.
                            </Card.Text>
                            <Button variant="outline-danger" onClick={removeProfileImage}>Remove Profile Image</Button>
                        </Card.Body>
                    </Card>
                ) : (
                    <Card className="bg-body-tertiary" style={{borderRadius: '10px'}}>
                        <Card.Body>
                            <Card.Title>Upload an Image of Yourself:</Card.Title>
                            <Dashboard uppy={uppy} plugins={['Webcam']}/>
                        </Card.Body>
                    </Card>
                )
            }
        </Container>
    );
}
