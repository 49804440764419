import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

export function FooterBar() {
    return (
        <Container className="mt-5">
            <Navbar className="main-footer d-flex justify-content-center navbarContainer">
                <Nav>
                    <Nav.Link href="/about">About</Nav.Link>
                    <Nav.Link href="/privacy">Privacy Policy</Nav.Link>
                    <Nav.Link href="/terms">Terms & Conditions</Nav.Link>
                    <Nav.Link href="/contact">Contact</Nav.Link>
                    <Nav.Link href="/stats">Statistics Info</Nav.Link>
                </Nav>
            </Navbar>
        </Container>
    );
}