import Container from "react-bootstrap/Container";


export function Contact() {
    return (
        <Container className="mt-5">
            <h1>Contact</h1>
            <ul>
                <li>By email: admin@genotyper.org</li>
            </ul>
        </Container>
    );
}