import {axios, backendUri} from "./config";

export const getAllPollsApi = (giveThumbnail) => axios.get(`${backendUri}/getAllPolls/${giveThumbnail}`);

export const getPollByShortTitleApi = (short_title) => axios.get(`${backendUri}/getPollByShortTitle/${short_title}`);

export const getPollsByAuthorApi = (author) => axios.get(`${backendUri}/getPollsByAuthor/${author}`);

export const getPollNormByShortTitle = (short_title) => axios.get(`${backendUri}/getPollNormByShortTitle/${short_title}`)

export const getMaxScoreApi = (short_title) => axios.get(`${backendUri}/getMaxScore/${short_title}`)