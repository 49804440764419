import {FormControlLabel, Radio} from "@mui/material";
import DOMPurify from 'dompurify';

export default function ChoiceRadio({choice, answer, setAnswer}) {
    const safeHTML = DOMPurify.sanitize(choice.text);
    return (
        <FormControlLabel key={choice.id}
                          label={<span className="choice-content" dangerouslySetInnerHTML={{__html: safeHTML}}/>}
                          control={
                              <Radio checked={answer && answer.choice_id === choice.id}
                                     onChange={() => {
                                         setAnswer({
                                             reply: choice.text,
                                             choice_id: choice.id,
                                             q_id: answer.q_id,
                                         });
                                     }}/>
                          }/>
    )
}