import {React, useRef, useState} from 'react';
import Container from "react-bootstrap/Container";
import {Card} from "react-bootstrap";
import {loginApi, signupApi} from "../../api/apiAuth";
import {Link} from "react-router-dom";
import AgreementPrompt from "./AgreementPrompt";

export default function Signup() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [success, setSuccess] = useState('');
    const agreementBox = useRef(null);
    const [loading, setLoading] = useState(false);

    const signUp = async () => {
        if (!agreementBox.current.checked) {
            setSuccess("Please agree to the terms and conditions!");
            return;
        }
        setLoading(true);
        const data = {
            name: name,
            email: email,
            password: password,
        };
        try {
            await signupApi(data);
            alert("Signup succeeded. A verification email has been sent. You must verify before logging in.");
            window.location = '/';
        } catch (e) {
            setSuccess("Failed!");
            console.log(e);
            alert("An error occurred: " + e.response.data.message);
        } finally {
            setLoading(false);
        }
        
    };

    return (
        <Container className='mt-5'>
            <Card className="m-auto align-self-center" style={{width: '36rem'}}>
                <Card.Body>
                    <div className="Auth-form-container">
                        <form className="Auth-form">
                            <div className="Auth-form-content">
                                <h3 className="Auth-form-title">Sign Up</h3>
                                <div className="form-group mt-3">
                                    <label>Full Name</label>
                                    <input
                                        type="name"
                                        className="form-control mt-1"
                                        placeholder="e.g Jane Doe"
                                        id="name"
                                        name="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group mt-3">
                                    <label>Email address</label>
                                    <input
                                        type="email"
                                        className="form-control mt-1"
                                        placeholder="Email Address"
                                        id="email"
                                        name="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group mt-3">
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        className="form-control mt-1"
                                        placeholder="Password"
                                        id="password"
                                        name="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <AgreementPrompt checkboxRef={agreementBox}/>
                                <div className="d-grid gap-2 mt-3">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            void signUp();
                                        }}
                                        disabled={loading} // Disable the button while loading
                                    >
                                        {loading ? (
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        ) : (
                                            'Sign Up'
                                        )}
                                    </button>
                                </div>
                                <br></br>
                                {success && <p className="success-message fw-bold">{success}</p>}
                            </div>
                        </form>
                    </div>
                    Already have an account? <Link to="/signin">Log In</Link>
                </Card.Body>
            </Card>
        </Container>
    )
}