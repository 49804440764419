import Container from "react-bootstrap/Container";
import {getToken, handleAuthenticationError, isAdmin} from "../../userAuth";
import React, {useEffect, useState} from "react";
import {getPollsByAuthorApi} from "../../api/apiAnon";
import {getAllCompletedPollsApi} from "../../api/apiUser";
import PollCard from "../common/PollCard";
import {Row} from "react-bootstrap";


export default function MyTests() {
    const [polls, setPolls] = useState(null);
    const [completedPolls, setCompletedPolls] = useState(null);

    useEffect(() => {
        const fetchPolls = async () => {
            try {
                if (isAdmin()) {
                    const response = await getPollsByAuthorApi(localStorage.getItem('email'));
                    setPolls(response.data);
                }
                const response2 = await getAllCompletedPollsApi({token: getToken()});
                setCompletedPolls(response2.data.responses);
            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error fetching polls', error);
            }

        };

        void fetchPolls();
    }, []);

    return (
        <Container className="border p-4">
            {
                completedPolls && <>
                    <h3>Completed Tests</h3>
                    <p>Manage each of all the tests you have taken by clicking on one of them below.</p>
                    <Row className="border-top p-4 align-items-center justify-content-center center">
                        {
                            completedPolls.map((completedPoll) => <PollCard poll={completedPoll}/>)
                        }
                    </Row>
                    {completedPolls.length === 0 && <em>You have not completed any tests.</em>}
                </>
            }
            {
                isAdmin() && polls && <>
                    <h3>ADMIN - Authored Polls</h3>
                    <Row className="border-top p-4 align-items-center justify-content-center center">
                        {
                            polls.map((poll) => <PollCard poll={poll}/>)
                        }
                        {
                            !polls.length && <p className="bg-body-tertiary">No Authored Polls</p>
                        }
                    </Row>
                </>
            }
        </Container>
    );
}