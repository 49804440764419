import {Button, Checkbox, FormControlLabel, FormGroup, IconButton, Stack, TextField} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {useRef} from "react";
import {makeId} from "../../utils";
import Container from "react-bootstrap/Container";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function MCQuestion({updateFunc, deleteFunc, question, cat}) {

    // TODO: MAKE SURE CHOICES ARE DISTINCT
    const quillRef = useRef(null);

    const spacingStyle = {
        marginRight: '15px',
    };


    // Deletes the question
    const deleteClick = () => {
        deleteFunc(question);
    }

    const changeInstructions = (event) => {
        let newQuestion = {...question};
        newQuestion.instructions = event.target.value;
        updateFunc(newQuestion);
    };

    const addChoiceClick = () => {
        let newChoices = [...question.choices];
        newChoices.push({id: makeId(5), text: "", score: 0.0})
        let newQuestion = {...question};
        newQuestion.choices = newChoices;
        updateFunc(newQuestion);
    }

    const removeChoiceClick = (ch_id) => () => {
        let newChoices = [...question.choices];
        let index = newChoices.findIndex(c => c.id === ch_id);
        newChoices.splice(index, 1);
        let newQuestion = {...question};
        newQuestion.choices = newChoices;
        updateFunc(newQuestion);
    }

    const changeCheck = (event) => {
        let newQuestion = {...question};
        newQuestion.multi = event.target.checked;
        updateFunc(newQuestion);
    }

    const skipChange = (event) => {
        let newQuestion = {...question};
        newQuestion.skippable = event.target.checked;
        updateFunc(newQuestion);
    }

    const changePrompt = (newPrompt) => {
        if (newPrompt !== question.prompt) {
            let newQuestion = {...question, prompt: newPrompt};
            updateFunc(newQuestion);
        }
    };


    const changeDiscrimination = (event) => {
        let newQuestion = {...question};
        newQuestion.discrimination = parseFloat(event.target.value)
        updateFunc(newQuestion);
    }

    const changeDiff = (event) => {
        let newQuestion = {...question};
        newQuestion.difficulty = parseFloat(event.target.value);
        updateFunc(newQuestion);
    }

    const changeText = (ch_id) => (event) => {
        let newChoices = [...question.choices];
        let index = newChoices.findIndex(choice => choice.id === ch_id);
        newChoices[index].text = event;
        let newQuestion = {...question};
        newQuestion.choices = newChoices;
        updateFunc(newQuestion);
    }

    const changeCorrect = (ch_id, score) => {
        let newChoices = [...question.choices];
        let index = newChoices.findIndex(choice => choice.id === ch_id);
        newChoices[index].score = score;
        let newQuestion = {...question};
        newQuestion.choices = newChoices;
        updateFunc(newQuestion);
    }

    const handlePaste = async (e) => {
        const clipboard = (e.clipboardData || window.clipboardData);
        const htmlContent = clipboard.getData("text/html");
        const wrapper = document.createElement('div');
        wrapper.innerHTML = htmlContent;
        const imgElements = Array.from(wrapper.querySelectorAll('img'));

        if (imgElements.length > 0) {
            e.preventDefault();  // Prevent Quill's default paste behavior.
            for (const imgElement of imgElements) {
                if (imgElement.src) {
                    fetch(imgElement.src)
                        .then(response => response.blob())
                        .then(insertImageBlob)
                        .catch(error => {
                            console.error('Error fetching the image:', error);
                        });
                }
            }
        }
    };

    const insertImageBlob = (blob) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const base64 = event.target.result;
            const quillInstance = quillRef.current.getEditor();
            const range = quillInstance.getSelection(true);
            quillInstance.insertEmbed(range.index, 'image', base64);
        };
        reader.readAsDataURL(blob);
    };


    return (
        <Container>
            <h4>{question.type}</h4>
            <ReactQuill
                value={question.prompt}
                onChange={changePrompt}
                ref={quillRef}
                onPast={handlePaste}
            />
            {question.type === "Multiple Choice" &&
                <Container>
                    <FormGroup>
                        <FormControlLabel control={
                            <Checkbox checked={question.multi} onChange={changeCheck}/>
                        } label="Allow Multiple Selections"/>
                        <FormControlLabel control={
                            <Checkbox checked={question.skippable} onChange={skipChange}/>
                        } label="Skippable"/>
                    </FormGroup>
                    <Stack direction="column">
                        {
                            question.choices.map((choice, ch_index) => {
                                return (
                                    <Stack direction="row" spacing={20} sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginBottom: '20px'
                                    }}>
                                        <h6>Choice #{ch_index + 1}</h6>

                                        <input
                                            type="checkbox"
                                            checked={choice.score === 1}
                                            onChange={(e) => changeCorrect(choice.id, e.target.checked ? 1.0 : 0.0)}
                                        />

                                        <ReactQuill
                                            value={choice.text}
                                            onChange={changeText(choice.id)}
                                            ref={quillRef}
                                            onPast={handlePaste}
                                            modules={{toolbar: false}}
                                            style={{height: '50px', width: '575px', resize: 'both', overflow: 'auto'}}
                                        />


                                        <IconButton size="small" onClick={removeChoiceClick(choice.id)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </Stack>
                                )
                            })
                        }
                        <Stack direction="row" spacing={3}
                               sx={{display: "flex", alignItems: "center", marginBottom: '20px'}}>
                            <Button variant="contained" onClick={addChoiceClick} sx={{width: "fit-content"}}>Add a
                                choice</Button>
                            <p>Check box for correct.</p>
                            <TextField
                                label="Instructions"
                                variant="outlined"
                                value={question.instructions} // Set value from question.instructions
                                onChange={changeInstructions} // Call changeInstructions on value change
                            />

                        </Stack>
                    </Stack>
                </Container>
            }
            <hr/>
            <Button style={spacingStyle} variant="contained" onClick={deleteClick}
                    sx={{width: "fit-content"}}>Delete</Button>
            {cat && (
                <>
                    <TextField
                        style={spacingStyle}
                        variant="outlined"
                        label="Discrimination"
                        name="discrimination"
                        type="number"
                        value={question.discrimination}
                        onChange={changeDiscrimination}
                        inputProps={{step: "0.01"}} // allows decimal values
                    />
                    <TextField
                        variant="outlined"
                        label="Difficulty"
                        name="difficulty"
                        type="number"
                        value={question.difficulty}
                        onChange={changeDiff}
                        inputProps={{step: "0.01"}} // allows decimal values
                    />
                </>
            )}
        </Container>
    )
}