import {useCallback, useEffect, useState} from "react";
import {Stack} from "@mui/material";
import '../../css/choice.css'
import CurrentQuestion from './CurrentQuestion'
import ControlButton from "./ControlButton";
import {extractNumber} from "../../utils";

export default function PollInteractionPanel({pollDetails, question, answers, newQuestion, lastQuestion, isCat}) {

    // Initialize answers array to match with questions
    const [selections, setSelections] = useState([]);
    const [answer, setAnswer] = useState({
        reply: '',
        choice_id: null,
        q_id: question.id,
    });

    useEffect(() => {
        const questionNumber = extractNumber(question.id);
        if (questionNumber !== null && answers[questionNumber] !== undefined) {
            // Update answer state if the corresponding answer is found
            setAnswer({
                choice_id: answers[questionNumber].choice_id,
                reply: answers[questionNumber].reply,
                q_id: answers[questionNumber].q_id,
                // You might also want to update choice_id if needed
            });
        }
    }, [question.id, answers]);

    const createSelectedChoices = (question, selections) =>
        question.choices.map(choice => ({
            ch_id: choice.id,
            ch_name: choice.text,
            value: selections.includes(choice.id),
        }));

    const onNext = useCallback(() => {
            let updatedAnswer = {
                ...answer,
                q_id: question.id
            };
            if (!question.multi) {
                newQuestion(updatedAnswer);
            } else {
                let selectedChoices = createSelectedChoices(question, selections);
                console.log('selection', selections, selectedChoices);
                setSelections([]);
                newQuestion({
                    reply: selectedChoices,
                    q_id: question.id,
                });
            }
            const CLEAR_ANSWER = {
                reply: '',
                choice_id: null,
            };
            setAnswer(CLEAR_ANSWER);
        },
        [question, answer, selections]);

    const onBack = useCallback(() => {
        lastQuestion();
    }, [lastQuestion]);

    return (
        <Stack direction="column" spacing={2} sx={{margin: 2, padding: 2}}>
            <h1>{pollDetails.title}</h1>
            <p>{pollDetails.details}</p>
            <hr/>
            <Stack direction="column" spacing={2} className="mt-5 mx-4 px-5">
                <CurrentQuestion question={question}
                                 answer={answer}
                                 selections={selections}
                                 setAnswer={setAnswer}
                                 setSelections={setSelections}
                />
                <Stack direction="row" spacing={2}>
                    <ControlButton handleClick={onBack}
                                   isDisabled={extractNumber(question.id) <= 0 || isCat}
                                   buttonText="BACK"
                    />
                    <ControlButton handleClick={onNext}
                                   isDisabled={!question.skippable && !answer.reply && selections.length === 0}
                                   buttonText="NEXT"
                    />
                </Stack>
            </Stack>
        </Stack>
    )
}