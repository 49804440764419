import React from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from 'highcharts/highcharts-more';

HighchartsMore(Highcharts);

const ErrorBarChart = ({theta, se, minTick, maxTick}) => {
    const options = {
        title: {
            text: null,
        },
        chart: {
            inverted: true,
            height: '7%',
        },
        xAxis: {
            visible: false,
        },
        yAxis: {
            title: null,
            visible: false,
            min: minTick,
            max: maxTick,
        },
        legend: {
            enabled: false,
        },
        tooltip: {
            enabled: false,
        },
        series: [{
            type: 'scatter',
            data: [theta],
        }, {
            type: 'errorbar',
            data: [
                [theta - se, theta + se]
            ]
        }],
        credits: {
            enabled: false
        },
    }
    return <HighchartsReact highcharts={Highcharts} options={options}/>;
};

export default ErrorBarChart;
