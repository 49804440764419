import {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getNormQuestions, submitNormResponse} from "../../api/apiUser";
import PollInteractionPanel from "./PollInteractionPanel";
import {getToken, handleAuthenticationError, isAdmin} from "../../userAuth";
import Container from "react-bootstrap/Container";

export default function TakeNormPoll() {
    const navigate = useNavigate();
    const {short_title} = useParams();
    const [poll, setPoll] = useState({
        details: null,
        question: null,
        isCat: false,
        qIdx: 0,
        questions: null,
        answers: [],
        scores: {score: 0, mean: 0, std: 1}
    });

    useEffect(() => {
        const getQuestions = async () => {
            try {
                const response = await getNormQuestions({
                    short_title: short_title,
                    token: getToken(),
                });
                console.log(response);
                setPoll(prevState => ({
                    ...prevState,
                    details: {
                        title: 'Norming Questions',
                        details: 'Questions for norming',
                        author: 'null',
                        short_title: short_title,
                    },
                    isCat: false,
                    questions: response.data.questions,
                    question: response.data.questions[0],
                }));
            } catch (error) {
                handleAuthenticationError(error);
                console.error('Error fetching results:', error);
            }
        }
        void getQuestions();
    }, []);

    const submitClick = async (currentAnswers) => {
        const answerData = currentAnswers.map(a => ({
            question_id: a.q_id,
            question_type: "text",
            answer: a.reply || a.selections,
        }));
        const data = {
            short_title: poll.details.short_title,
            results: answerData,
            token: getToken(),
        }
        try {
            console.log(data);
            await submitNormResponse(data);
            navigate(`/view/${poll.details.short_title}`);
        } catch (error) {
            console.log(error);
            handleAuthenticationError(error);
            alert('Submission failed!');
            window.location.reload();
        }
    }

    const newQuestion = useCallback(async (answer) => {
        const updatedAnswers = [...poll.answers, answer];
        const inc = poll.qIdx + 1;
        if (inc === poll.questions.length) {
            await submitClick(updatedAnswers);
        }
        setPoll(prevState => ({
            ...prevState,
            answers: updatedAnswers,
            question: poll.questions[inc],
            qIdx: inc
        }));
    }, [poll]);

    return (
        <Container>
            <div>
                {
                    poll.details && poll.question ?
                        <PollInteractionPanel
                            pollDetails={poll.details}
                            question={poll.question}
                            newQuestion={newQuestion}
                        />
                        :
                        <div>Loading...</div>
                }
                {
                    isAdmin() && poll.scores && poll.question ?
                        <>
                            <p>Admin panel:</p>
                            <p>{`se: ${poll.scores.std}`}</p>
                            <p>{`CAT mode: ${poll.isCat}`}</p>
                            <p>{`Skippable: ${poll.question.skippable || false}`}</p>
                        </>
                        :
                        <></>
                }
            </div>
        </Container>
    )
}