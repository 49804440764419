import {Button, Checkbox, FormControlLabel} from "@mui/material";
import {useRef} from "react";
import Container from "react-bootstrap/Container";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function ShortAnswerQuestion({updateFunc, deleteFunc, question}) {

    // TODO: MAKE SURE CHOICES ARE DISTINCT
    const quillRef = useRef(null);

    // Deletes the question
    const deleteClick = () => {
        deleteFunc(question);
    }

    const changePrompt = (event) => {
        let newQuestion = {...question};
        newQuestion.prompt = event;
        updateFunc(newQuestion);
    }

    const skipChange = (event) => {
        let newQuestion = {...question};
        newQuestion.skippable = event.target.checked;
        updateFunc(newQuestion);
    }

    const handlePaste = async (e) => {
        const clipboard = (e.clipboardData || window.clipboardData);
        const htmlContent = clipboard.getData("text/html");
        const wrapper = document.createElement('div');
        wrapper.innerHTML = htmlContent;
        const imgElements = Array.from(wrapper.querySelectorAll('img'));

        if (imgElements.length > 0) {
            e.preventDefault();  // Prevent Quill's default paste behavior.
            for (const imgElement of imgElements) {
                if (imgElement.src) {
                    fetch(imgElement.src)
                        .then(response => response.blob())
                        .then(insertImageBlob)
                        .catch(error => {
                            console.error('Error fetching the image:', error);
                        });
                }
            }
        }
    };

    const insertImageBlob = (blob) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const base64 = event.target.result;
            const quillInstance = quillRef.current.getEditor();
            const range = quillInstance.getSelection(true);
            quillInstance.insertEmbed(range.index, 'image', base64);
        };
        reader.readAsDataURL(blob);
    };


    return (
        <Container>
            <h4>{question.type}</h4>
            <ReactQuill
                value={question.prompt}
                onChange={changePrompt}
                ref={quillRef}
                onPast={handlePaste}
            />
            <FormControlLabel control={
                <Checkbox checked={question.skippable} onChange={skipChange}/>
            } label="Skippable"/>
            <hr/>
            <hr/>
            <Button variant="contained" onClick={deleteClick} sx={{width: "fit-content"}}>Delete</Button>
        </Container>
    )
}