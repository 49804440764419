import Container from "react-bootstrap/Container";


export function About() {
    return (
        <Container className="mt-5">
            <h1>About</h1>
            <p>
                Genotyper.org is a recreational free-to-use platform for taking psychometric tests and uploading DNA
                additional analysis.
            </p>
        </Container>
    );
}