import {React, useState} from 'react';
import Container from "react-bootstrap/Container";
import {Card} from "react-bootstrap";
import {loginApi, loginGoogleApi} from "../../api/apiAuth";
import {Link} from "react-router-dom";
import {getAnonymousKey, loginUser} from "../../userAuth";
import {GoogleLogin} from '@react-oauth/google';

export default function Signin({redirect = true}) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [success, setSuccess] = useState('');

    function redirectPage() {
        if (redirect) {
            window.location = '/';
        } else {
            window.location.reload();
        }
    }

    const logIn = async () => {
        const data = {
            email: email,
            password: password,
            anon_key: getAnonymousKey(),
        };
        try {
            const ret = await loginApi(data);
            loginUser(email, ret.data);
            redirectPage();
        } catch (e) {
            setSuccess("Log in Failed!");
            console.log(e);
        }
    };

    const responseGoogle = async (response) => {
        if (!response['credential']) {
            alert('Unknown Google authentication error - try again or try alternative login.');
            console.log(response);
            return
        }
        response = {
            ...response,
            anon_key: getAnonymousKey(),
        }
        try {
            const ret = await loginGoogleApi(response);
            loginUser(ret.data.email, ret.data);
            redirectPage();
        } catch (e) {
            setSuccess("Log in Failed!");
            console.log(e);
        }
    }

    return (
        <Container className='mt-5'>
            <Card className="m-auto align-self-center" style={{width: '36rem'}}>
                <Card.Body>
                    <div className="Auth-form-container">
                        <form className="Auth-form">
                            <div className="Auth-form-content">
                                <h3 className="Auth-form-title">Log In</h3>
                                <div className="form-group mt-3">
                                    <label>Email address</label>
                                    <input
                                        type="email"
                                        className="form-control mt-1"
                                        placeholder="Email Address"
                                        id="email"
                                        name="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group mt-3">
                                    <label>Password</label>
                                    <input
                                        type="password"
                                        className="form-control mt-1"
                                        placeholder="Password"
                                        id="password"
                                        name="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="d-grid gap-2 mt-3">
                                    <button type="submit" className="btn btn-primary" onClick={(e) => {
                                        e.preventDefault();
                                        void logIn();
                                    }}>
                                        Log In
                                    </button>
                                </div>
                                <br></br>
                                {success && <p className="success-message">{success}</p>}
                            </div>
                        </form>
                    </div>
                    Don't have an account? <Link to="/signup">Sign Up</Link>
                    <br/>
                    <div>
                        <br/>
                        <GoogleLogin onSuccess={responseGoogle} onError={responseGoogle} useOneTap/>
                    </div>
                </Card.Body>
            </Card>
        </Container>
    )
}