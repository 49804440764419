import React from 'react';
import {Bar} from 'react-chartjs-2';
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function ScoreBarChart({scores}) {
    // Filter out non-number values and then count the frequency of each score
    const scoreFrequency = scores
        .filter(score => score != null && !isNaN(score)) // Filter out null/undefined and non-numeric values
        .reduce((acc, score) => {
            const scoreKey = score.toString(); // Convert to string for object keys
            acc[scoreKey] = (acc[scoreKey] || 0) + 1;
            return acc;
        }, {});

    // Sort the scores and create chart data
    const sortedScores = Object.keys(scoreFrequency).map(Number).sort((a, b) => a - b); // Lowest to highest
    const chartData = {
        labels: sortedScores.map(String),
        datasets: [{
            label: 'Score Frequency',
            data: sortedScores.map(score => scoreFrequency[score.toString()]),
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
        }],
    };

    // Chart options
    const options = {
        indexAxis: 'y',
        scales: {
            y: {
                beginAtZero: true,
            },
            x: {
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Frequency of Scores',
            },
        },
    };

    return <Bar data={chartData} options={options}/>;
}
