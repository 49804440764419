import React, { useState, useEffect } from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem, Button, OutlinedInput, Box, Checkbox, ListItemText, } from '@mui/material';
import Container from "react-bootstrap/Container";
import { Card} from "react-bootstrap";
import {sendAccountInfo} from "../../api/apiUser";
import {getToken, handleAuthenticationError} from "../../userAuth";
import { useNavigate } from 'react-router-dom';
import { getData } from 'country-list';
import { checkInfo } from '../../api/apiUser';

export default function Info() {
  let navigate = useNavigate();
  const [gender, setGender] = useState([]);
  const [age, setAge] = useState([]);
  const [ethnicity, setEthnicity] = useState([]);
  const [fetish, setFetish] = useState([]);
  const [country, setCountry] = useState([]);
  const countries = getData(); 
  const [birth, setBirth] = useState([]);

  useEffect(() => {
      async function fetchData() {
          try {
              let res = await checkInfo({token: getToken()});
              let d = res.data.message;
              if (d != 'Info not found')
              {
                setAge(d.age);
                setGender(d.gender);
                setEthnicity(d.ethnicity);
                setFetish(d.fetish);
                setCountry(d.country);
                setBirth(d.birth);
              }
          } catch (error) {
              handleAuthenticationError(error);
              console.error('Error fetching data:', error);
          }
      }
      void fetchData();
  }, []);

  async function submit(event) {
    event.preventDefault();
    console.log({ gender, age, ethnicity, country, birth, fetish });
    try{
        let info = {gender, age, ethnicity, country, birth, fetish};
        await sendAccountInfo({ info, token: getToken() });
        navigate('/');

    }
    catch(e) {
        handleAuthenticationError(e);
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setEthnicity(
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  
  

  return (
    <Container className="p-4 pb-3 bg-body-tertiary" style={{borderRadius: '10px', maxWidth: '36rem'}}>
      <Card className="bg-body-tertiary" style={{borderRadius: '10px'}}>
        <Card.Body>
        <Card.Title>Give Account Info Before Use:</Card.Title>
        <Box
          component="form"
          onSubmit={submit}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& .MuiTextField-root': { m: 1, width: '25ch' },
          }}
        >
          {/* Gender Dropdown */}
          <TextField
            select
            label="Gender"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            variant="outlined"
          >
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </TextField>

          <TextField
            select
            label="Sexual Orientation"
            value={fetish}
            onChange={(e) => setFetish(
              typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value
            )}
            variant="outlined"
          >
            <MenuItem value="heterosexual">Heterosexual</MenuItem>
            <MenuItem value="homosexual">Homosexual</MenuItem>
            <MenuItem value="bisexual">Bisexual</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </TextField>

          <TextField
            type="date"
            label="Birthday"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ marginRight: 2, width: 220 }}
          />



              <TextField
                select
                label="Nationality"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                variant="outlined"
                fullWidth
              >
                {countries.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                select
                label="Birth Country"
                value={birth}
                onChange={(e) => setBirth(e.target.value)}
                variant="outlined"
                fullWidth
              >
                {countries.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>      

              <FormControl
                fullWidth
                sx={{ minWidth: 100, maxWidth: 255, width: '100%', marginBottom: 3 }}
              >
                <InputLabel id="ethnicity-label">Ethnicity</InputLabel>
                <Select
                  labelId="ethnicity-label"
                  id="ethnicity-select"
                  multiple
                  value={ethnicity}
                  onChange={handleChange}
                  input={<OutlinedInput label="Ethnicity" />}
                  renderValue={(selected) => selected.join(', ')}
                >
                  <MenuItem value="White">
                    <Checkbox checked={ethnicity.includes('White')} />
                    <ListItemText primary="White, European" />
                  </MenuItem>
                  <MenuItem value="Jew">
                    <Checkbox checked={ethnicity.includes('Jew')} />
                    <ListItemText primary="Jewish" />
                  </MenuItem>
                  <MenuItem value="Black">
                    <Checkbox checked={ethnicity.includes('Black')} />
                    <ListItemText primary="Black, African" />
                  </MenuItem>
                  <MenuItem value="NE Asian">
                    <Checkbox checked={ethnicity.includes('NE Asian')} />
                    <ListItemText primary="Northeast Asian: Chinese, Japanese, Korean" />
                  </MenuItem>
                  <MenuItem value="SE Asian">
                    <Checkbox checked={ethnicity.includes('SE Asian')} />
                    <ListItemText primary="Southeast Asian: Thai, Indonesian, Cambodian, Philippine, and others" />
                  </MenuItem>
                  <MenuItem value="S Asian">
                    <Checkbox checked={ethnicity.includes('S Asian')} />
                    <ListItemText primary="South Asian: Indian, Sri Lankan, Bangladeshi, and others" />
                  </MenuItem>
                  <MenuItem value="Polynesian">
                    <Checkbox checked={ethnicity.includes('Polynesian')} />
                    <ListItemText primary="Polynesian, Hawaiian, or other Pacific Islander" />
                  </MenuItem>
                  <MenuItem value="Hispanic">
                    <Checkbox checked={ethnicity.includes('Hispanic')} />
                    <ListItemText primary="Hispanic, Amerindian, Native American" />
                  </MenuItem>
                  <MenuItem value="NA">
                    <Checkbox checked={ethnicity.includes('NA')} />
                    <ListItemText primary="North African: Egyptian, Moroccan, Tunisian, Algerian, Libyan" />
                  </MenuItem>
                  <MenuItem value="ME">
                    <Checkbox checked={ethnicity.includes('ME')} />
                    <ListItemText primary="Middle Eastern: Syrian, Lebanese, Iraqi, Saudi Arabian, Yemenite, Iranian, Afghan, Pakistani, and others" />
                  </MenuItem>
                  <MenuItem value="Turk">
                    <Checkbox checked={ethnicity.includes('Turk')} />
                    <ListItemText primary="Turkic: Turkish, Turkmenistan, Kazakh, and others" />
                  </MenuItem>
                </Select>
              </FormControl>

          {/* Submit Button */}
          <Button type="submit" variant="contained" sx={{ mt: 3 }}>
            Submit
          </Button>
        </Box>
    </Card.Body>
    </Card>
    </Container>
  );
}