import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import bellCurve from "highcharts/modules/histogram-bellcurve";

bellCurve(Highcharts);

export function BellCurve(props) {
    const score = props.scores.score;
    const mean = props.scores.mean;
    const std = props.scores.std;
    const se = props.se;
    const truncate = props.truncate;

    const generateBellCurveData = (mean, std) => {
        const data = [];
        for (let i = mean - 4 * std; i <= mean + 4 * std; i += 0.1) {
            const y = (1 / (std * Math.sqrt(2 * Math.PI))) * Math.exp(-0.5 * Math.pow((i - mean) / std, 2));
            data.push([i, y]);
        }
        return data;
    };

    const truncateData = (data, truncate) => {
        if (truncate === undefined) {
            return data;
        }
        return data.filter(point => point[0] <= truncate);
    };

    const bellCurveData = generateBellCurveData(mean, std);
    const truncatedData = truncateData(bellCurveData, truncate);

    const config = {
        chart: {
            events: {
                render: function () {
                    if (props.setTicks) {
                        props.setTicks(this.xAxis.find(x => x.tickPositions.length > 0).tickPositions);
                    }
                }
            },
            height: props.height || null,
        },

        title: {
            text: props.title || null
        },

        legend: {
            enabled: false
        },

        tooltip: {
            enabled: false
        },

        xAxis: [
            {
                visible: false,
            },
            {
                visible: true,
                plotLines: [{
                    color: '#DD0000',
                    width: 2,
                    value: score
                }],
                ...se && {
                    plotBands: [{
                        from: score - se,
                        to: score + se,
                        color: 'rgba(68, 170, 213, .2)'
                    }]
                },
            }
        ],

        yAxis: [
            {
                visible: false
            },
            {
                visible: false
            }
        ],

        series: [
            {
                name: "Normal Distribution",
                type: "area",
                xAxis: 1,
                yAxis: 1,
                data: truncatedData,
                zIndex: -1,
                enableMouseTracking: false,
                color: '#EEEEFF'
            }
        ],

        credits: {
            enabled: false
        },
    }

    return <HighchartsReact constructorType={"chart"} highcharts={Highcharts} options={config} />;
}
