import {axios, backendUri} from "./config";

export const submitPollResponseByUserApi = (props) => axios.post(`${backendUri}/submitPollResponseByUser`, props);

export const getPollResponseByUserApi = (props) => axios.post(`${backendUri}/getPollResponseByUser`, props);

export const removePollResponseByUserApi = (props) => axios.post(`${backendUri}/removePollResponseByUser`, props);

export const getAllCompletedPollsApi = (props) => axios.post(`${backendUri}/getAllCompletedPolls`, props);

export const getNextCatApi = (props) => axios.post(`${backendUri}/getNextCat`, props);

export const getNormScores = (props) => axios.post(`${backendUri}/getNormScores`, props);

export const getNormQuestions = (props) => axios.post(`${backendUri}/getNormQuestions`, props);

export const submitNormResponse = (props) => axios.post(`${backendUri}/submitNormResponse`, props);

export const forceToken = (props) => axios.post(`${backendUri}/forceToken`, props);

export const uploadProfileImageApi = (props) => axios.post(`${backendUri}/uploadProfileImage`, props);

export const getProfileImageApi = (props) => axios.post(`${backendUri}/getProfileImage`, props, {responseType: 'blob'});

export const removeProfileImageApi = (props) => axios.post(`${backendUri}/removeProfileImage`, props);

export const sendAccountInfo = (props) => axios.post(`${backendUri}/sendAccountInfo`, props);

export const checkInfo = (props) => axios.post(`${backendUri}/checkInfo`, props);

export const getAllUserScoresApi = (props) => axios.post(`${backendUri}/getAllUserScores`, props);
