import {Checkbox, FormControlLabel} from "@mui/material";
import DOMPurify from "dompurify";

export default function ChoiceCheckbox({choice, selections, setSelections}) {
    const safeHTML = DOMPurify.sanitize(choice.text);
    return (
        <FormControlLabel key={choice.id}
                          label={<span className="choice-content" dangerouslySetInnerHTML={{__html: safeHTML}}/>}
                          control={
                              <Checkbox checked={selections.includes(choice.id)}
                                        onChange={() => {
                                            setSelections(prev => {
                                                if (prev.includes(choice.id)) {
                                                    return prev.filter(id => id !== choice.id);
                                                } else {
                                                    return [...prev, choice.id];
                                                }
                                            });
                                        }}/>
                          }/>
    )
}